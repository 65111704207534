import { pageConstants } from '../constants'
import moment from 'moment'

const initialState = {	
    pages: [
        {
            path: '/casting/',
            template: 'modelList',
            title: 'Casting',
            fixed: true
        }
    ],
    loading: false
}

export function pages(state = initialState, action) {
    //console.log(action.type)
    switch (action.type) {
        case pageConstants.GETPAGE_REQUEST:
            //console.log('ss')
            return {
                ...state
            }
        case pageConstants.GETPAGE_SUCCESS:

            const { pages, events } = state;
			const index = pages.findIndex(page => page.path === action.page.path)

			if(index>-1) {
				pages[index] = action.page
			} else {
				pages.push(action.page)
            }
            
            //console.log(pages)
           
            return {
                ...state,
                pages,
            }
        case pageConstants.GETPAGE_FAILURE:
            console.log('ss')
            return { 
                error: action.error
            }

        case pageConstants.SETLOADING_SUCCESS:
            //console.log('ss')
            return {
                ...state,
                loading: action.loading
            }

        default:
            return state
    }
}