import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom'
import { withRouter, Link } from 'react-router-dom'
import styles from './Overlay.module.sass'

import Icon from 'components/Icon'

import LazyImage from 'components/page/parts/lazyImage'

const Index = ({data, active, location, history}) => {
    const [visible, setVisible] = useState(false)

    const [currentSlide, setCurrentSlide] = useState(null)
    const [touching, setTouching] = useState({
        isTouching: false,
        touchXstart: null,
        touchX: null
    })

    const skipAnimate = useRef(null)

    const [ dimensions, setDimensions ] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        const resize = () => {  
            skipAnimate.current = true  
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            })  
        }

        window.addEventListener('resize', resize)

        return () => {
            window.removeEventListener('resize', resize)
        }

    }, [])

    useEffect(() => {
      
        document.addEventListener('keydown', keyPressHandler);

        return () => {
            document.removeEventListener('keydown', keyPressHandler);
        }

    }, [currentSlide])

    const keyPressHandler = (event) => {
        console.log(event.keyCode)

        switch(event.keyCode) {
            case 27:
                history.goBack()
                break;

            case 37:
                setCurrentSlide(currentSlide-1)
                break;

            case 39:
                setCurrentSlide(currentSlide+1)
                break;

            default:
                break;
        }
    }

    useEffect(() => {
        if (active && !visible) {
            /*
            scrollTop = window.scrollY || window.pageYOffset;

            let height = document.documentElement.scrollHeight;
      
            let test = (scrollTop + window.innerHeight / 2) / height * 100
    
            let styleTest = `transform-origin: center ${test}%; position: fixed; width: 100%; top: -${scrollTop}px`;


            document.getElementById('page').style.cssText = styleTest;
            document.getElementById('page').classList.add("test");
            */
            setVisible(true)
            
        }

        if (!active && visible) {

            //document.getElementById('page').classList.remove("test");

            setTimeout(() => {
                setVisible(false)
                //document.getElementById('page').style.cssText = ''
                //window.scroll(0, scrollTop)
                
            }, 500);
        }
    }, [active, visible])

    

    const getImage = (slide) => {
        

        let test = slide

        let test2 = test%data.length;

        //console.log(test2)

        if(test2<0) {
            test2 = data.length+test2
        }

        return test2

    }

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const gallery = parseInt(params.get('i'))

        //console.log(gallery)
        //console.log(galleryImages)

        let index = data.findIndex(image => image.ID === gallery);

        if (index > -1) {

            //console.log('SETED ' + index);

            setCurrentSlide(index)

            //this.props.setClosed(false)
        }

    }, [data])

    /* */

    const handleTouchStart = (e) => {
        console.log('handleTouchStart');
        setTouching({
            ...touching,
            touchXstart: e.touches[0].clientX
        })
    }

    const handleTouchMove = (e) => {
        let touchX = e.touches[0].clientX - touching.touchXstart;

        console.log(e.touches[0].clientX, touching.touchXstart);

        if (Math.abs(touchX) > 10) {
            setTouching({
                ...touching,
                isTouching: true,
                touchX
            })
        }
    }

    const handleTouchEnd = (e) => {
        console.log(e.changedTouches[0].clientX)
        console.log('handleTouchEnd');
        console.log(touching);

        if (touching.isTouching) {

            setTouching({
                ...touching,
                isTouching: false
            })

            if (touching.touchX > 0) {
                setCurrentSlide(currentSlide-1)
            } else {
                setCurrentSlide(currentSlide+1)
            }

        }
    }


    /* */

    let slides = []

    for(let i = currentSlide-2; i<=currentSlide+2; i++) {
        let left = i*100

        let item = data[ getImage(i) ]

        let className = i<currentSlide ? 'previous' : i>currentSlide ? 'next' : null

        let extraLeft = 0

        const scaleWidth = dimensions.width*0.8/item.width
        const scaleHeight =dimensions.height/item.height
    
        let scale = scaleWidth < scaleHeight ? scaleWidth : scaleHeight


        if(dimensions.width*0.8/item.width > dimensions.height/item.height) {
            extraLeft =  ((dimensions.width*0.8) - (scale * item.width))/2 
            scale = 'height fitted' + scale
            
           
        } else {
            scale = 'width fitted' + scale
        }

        if(i===currentSlide) {
            extraLeft = 0
        }

        //console.log(extraLeft)

        if(i<currentSlide) {
            extraLeft = extraLeft * -1
        }

        //console.log(extraLeft)

        //extraLeft = extraLeft * -1
        
        
        slides.push(
            <div className={className} style={{left: `${left}%`}} key={i}>            
                <div
                    className="image"
                    style={{backgroundImage: `url(${item.preview})`, transform: `translateX(${-1*extraLeft}px)`}}>
                    {(i===currentSlide) &&
                         <LazyImage
                            image={item.path} 
                            style={{backgroundImage: `url(${item.path})`}}
                         />
                    }
                </div>
            </div>
        )
    }

    if (!visible) return null

    const classes = [styles.overlay, active ? 'in' : 'out']
    skipAnimate.current || touching.isTouching && classes.push('noanimate')

    skipAnimate.current = false

    let translateX = -currentSlide*100;

    if (touching.isTouching) {
        translateX += (touching.touchX / window.innerWidth) * 100;
    }

    //classes.push('noanimate')

    return ReactDOM.createPortal(
        <div className={classes.join(' ')}>
            <div
                className="slides"
                onTouchStart={handleTouchStart} 
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                >
                <div style={{transform: `translateX(${translateX}%)`}}>
                    {slides}
                </div>
            </div>
            <span className="button previous" onClick={() => setCurrentSlide(currentSlide-1)}>
                <Icon type="arrow" />
            </span>
            <span className="button next"  onClick={() => setCurrentSlide(currentSlide+1)}>
                <Icon type="arrow" />
            </span>
            <span className="close" onClick={() => history.goBack()} />
        </div>,
        document.getElementById("app")
    );
};

export default withRouter(Index);