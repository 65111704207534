export const configConstants = {
    GETCONFIG_REQUEST: 'GETCONFIG_REQUEST',
    GETCONFIG_SUCCESS: 'GETCONFIG_SUCCESS',
    GETCONFIG_FAILURE: 'GETCONFIG_FAILURE',

    SETMEASUREMENTS_REQUEST: 'SETMEASUREMENTS_REQUEST',
    SETMEASUREMENTS_SUCCESS: 'SETMEASUREMENTS_SUCCESS',

    RESIZE_REQUEST: 'RESIZE_REQUEST',
    RESIZE_SUCCESS: 'RESIZE_SUCCESS',

    SETCOOKIE_REQUEST: 'SETCOOKIE_REQUEST',
    SETCOOKIE_SUCCESS: 'SETCOOKIE_SUCCESS',
};