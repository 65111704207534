import React, { useRef } from 'react';
import { usePreload } from 'hooks'

const Index = ({image, className, style, onClick}) => {
    const ref = useRef()
    const { loaded } = usePreload({ref, image})

    const classes = ['lazyload']
    className && classes.push(className)
    
    if(loaded) {
        classes.push('loaded')
        style = {...style, backgroundImage: `url(${image})`}
    }

    return (    
        <div
            className={classes.join(' ')}
            ref={ref} 
            style={style} 
            onClick={() => onClick ? onClick() : null} />
    )
};

export default Index;