import React, { useEffect, useState, useRef} from 'react';
import { connect } from 'react-redux'
import { withRouter, Route, Switch, useHistory } from 'react-router-dom'

import './App.sass';
import { resize } from './actions'

import Navigation from 'components/navigation'
import Page from 'components/page'
import Footer from 'components/footer'
import Cookie from 'components/cookie'

function App({location, resize}) {

    useEffect(() => {
        window.addEventListener('resize', resize)

        return () => {
            window.removeEventListener('resize', resize)
        }
    }, [])


    let newPath = location.pathname 

    if(newPath === '/models/') {
        newPath = '/artists/'
    } 



    const [ pages, setPages ] = useState([newPath])

  
    const lastPath = useRef(newPath)

    console.log('init:' + newPath)

    const history = useHistory();
    
    useEffect(() => {
        return history.listen(location => {
            //console.log(location.pathname )

            let newPath = location.pathname 

            if(newPath === '/models/') {
                newPath = '/artists/'
            } 

            
    
            if(lastPath.current !== newPath) {
               
                console.log( lastPath.current + " / " + newPath)
    
                const pagesNew = [lastPath.current, newPath] 
                
                lastPath.current = newPath
                
                console.log(pagesNew)

                setPages( pagesNew )
    
                if(pagesNew.length>1) {
                    setTimeout(() => {
                        
                        const pagesNew2 = [...pagesNew]
                        pagesNew2.splice(0, 1)
                        setPages( pagesNew2 )
                    
                    }, 200);
                }

                if (history.action === 'POP') {
                    console.log('BACK BUTTON')
                    console.log(newPath )
                } else {
                    console.log('NEXT BUTTON')

                  
                    setTimeout(() => {
                        
                        window.scrollTo(0, 0)
                    
                    }, 10);
                   
                }
            }
        })
    }, [])

    /*
    useEffect(() => {
        let newPath = location.pathname 

        if(newPath === '/models/') {
            newPath = '/artists/'
        } 

        if(lastPath.current !== newPath) {
            lastPath.current = newPath

            const pagesNew = [...pages, newPath]     
            setPages( pagesNew )

            if(pagesNew.length>1) {
                setTimeout(() => {
                    
                    const pagesNew2 = [...pagesNew]
                    pagesNew2.splice(0, 1)
                    setPages( pagesNew2 )
                
                }, 200);
            }
        }

    }, [location])
    */

    //console.log('-----------------')
    //console.log(pages)
   
    return (
        <>
            <Navigation />
            <div id="content">
                {pages.map((path, i) => {

                    let type = 'in'
                    let style = {}
                    
                    //

                    
                    if(i<pages.length-1) {
                        console.log('out')
                        type = 'out'
                        //style = {marginTop: `-${window.pageYOffset}px`}
                    }
                    

                    //console.log(path, type)

                    console.log(i, pages.length-1, path, type)
                    
                    return <Page path={path} key={path} type={type} style={style} />

                })}
            </div>
           
            <Footer />
        </>
    );
}

const mapStateToProps = (state, props) => {
    const { pages } = state.pages
	//const data = pages.find(page => page.path === props.location.pathname) 

	return {
        pages
	}
}

const mapDispatchToProps = dispatch => ({
    resize: () => dispatch(resize()),
    //setLanguage: (language) => dispatch(setLanguage(language)), 
})

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(App))
