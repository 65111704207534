import React from 'react';
import styles from './About.module.sass'

import Slider from 'components/page/parts/slider'
import { useState, useEffect } from 'react';

import LazyImage from 'components/page/parts/lazyImage'

const Index = ({ data, style }) => {
    const [scrollTop, setScrollTop] = useState(0)

    useEffect(() => {
        //console.log('header', ref.current)
        const scroll = () => {
            const scrollTop = window.scrollY
            setScrollTop(scrollTop)
        }

        window.addEventListener('scroll', scroll)

        return () => {
            window.removeEventListener('scroll', scroll)
        }

    }, [])

    console.log(data)

    let image = data.content.image

    return (
        <div className={styles.about + ' center'}>
            <div className="left">
                <div className={'text' + (image ? ' image': '')}>
                    <span className="border">
                        <h2 dangerouslySetInnerHTML={{__html: data.title.toLowerCase()}} style={style.borderTitle}></h2>
                    </span>
                    <div>
                        <div className="rich" dangerouslySetInnerHTML={{__html: data.content.text}}></div>
                        {image && 
                            <div>
                                
                                <LazyImage 
                                    image={image.path}
                                    style={{ paddingBottom: `${image.height / image.width * 100}%`,
                                    backgroundImage: `url(${image.path})`}}
                                    />
                                
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;