export const bookmarkConstants = {
    ADDREMOVETOCASTING_REQUEST: 'ADDREMOVETOCASTING_REQUEST',
    ADDREMOVETOCASTING_SUCCESS: 'ADDREMOVETOCASTING_SUCCESS',

    REMOVEALLFROMCASTING_REQUEST: 'REMOVEALLFROMCASTING_REQUEST',
    REMOVEALLFROMCASTING_SUCCESS: 'REMOVEALLFROMCASTING_SUCCESS',

    ADDREMOVETOPDF_REQUEST: 'ADDREMOVETOPDF_REQUEST',
    ADDREMOVETOPDF_SUCCESS: 'ADDREMOVETOPDF_SUCCESS',

    ADDALLIMAGESTOPDF_REQUEST: 'ADDALLIMAGESTOPDF_REQUEST',
    ADDALLIMAGESTOPDF_SUCCESS: 'ADDALLIMAGESTOPDF_SUCCESS',

    REMOVEALLIMAGESFROMPDF_REQUEST: 'REMOVEALLIMAGESFROMPDF_REQUEST',
    REMOVEALLIMAGESFROMPDF_SUCCESS: 'REMOVEALLIMAGESFROMPDF_SUCCESS'
};