import React from 'react';
import styles from './Signup.module.sass'
import stylesForm from 'components/page/parts/form/Form.module.sass'

import { Link } from 'react-router-dom'

import { useState, useEffect } from 'react';

import LazyImage from 'components/page/parts/lazyImage'

import { TextArea, FileUpload, Checkbox } from 'components/page/parts/form'

import { ValidateEmail } from 'helper'


const Index = ({ data, style }) => {
    const [formData, setFormData] = useState({
        email: '',
        name: '',
        text: '',
        age: '',
        height: '',
        country: '',
        files: [],
        privacy: false
    })

    const [error, setError] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (name, value) => {
        setFormData({ ...formData, [name]: value })
    }

    const onChangeFile = (props) => {
        const newData = { ...formData }

        newData[props.type] = null

        let files = newData['files']

        const index = files.findIndex(file => file.type === props.type)

        if (index > -1) {
            files[index] = props
        } else {
            files.push(props)
        }

        if (!props.base64) {
            files = files.filter(file => file.type !== props.type)
        }

        newData['files'] = files

        setFormData(formData)
    }

    const handleSubmit = () => {

        setError(false)

        const image1 = formData.files.find(file => file.type === 'image1')

        console.log(isLoading)
        console.log(formData)

        if (isLoading ||
            !formData.privacy ||
            !ValidateEmail(formData.email) ||
            !formData.name ||
            !formData.text ||
            !formData.age ||
            !formData.height ||
            !formData.country ||
            !image1
        ) {
            setError(true)
            return
        }

        setIsLoading('loading');

        let target = 'https://backend.mintartistmanagement.world/api/signup.php'
        const data = new FormData();

        for (var i = 1; i <= 4; i++) {

            const myFile = document.getElementById('fileimage' + i).files[0];


            data.append('file' + i, myFile);

        }

        data.append('email', formData.email);
        data.append('name', formData.name);
        data.append('message', formData.text);

        data.append('age', formData.age);
        data.append('height', formData.height);
        data.append('country', formData.country);


        fetch(target, {
            method: "POST",
            body: data
        })
            .then(response => response.json())
            .then(data => {
                setIsLoading('loaded');
                console.log('finished')
            });
    }

    console.log(data)

    let image = data.content.image

    return (
        <div className={styles.signup + ' center'}>
            <div className="left">
                <div className="form">
                    <span className="border">
                        <h2 dangerouslySetInnerHTML={{ __html: data.title.toLowerCase() }} style={style.borderTitle}></h2>
                    </span>
                    <div>
                        <div className={stylesForm.form}>
                            <div>
                                <input type="text" value={formData.email} placeholder="Your email *" onChange={e => handleChange('email', e.target.value)} />
                                <input type="text" value={formData.name} placeholder="Your name *" onChange={e => handleChange('name', e.target.value)} />         
                                <input type="number" className="half" value={formData.age} placeholder="Your age *" onChange={e => handleChange('age', e.target.value)} />
                                <input type="number" className="half" value={formData.height} placeholder="Your height (cm) *" onChange={e => handleChange('height', e.target.value)} />                          
                                <input type="text" value={formData.country} placeholder="Your location/country *" onChange={e => handleChange('country', e.target.value)} />
                                <TextArea value={formData.text} onChange={value => handleChange('text', value)} />
                                <Checkbox
                                    checked={formData.privacy}
                                    onClick={() => handleChange('privacy', !formData.privacy)}
                                >
                                    By submitting the application form, you agree to your data being used to process your request (for further information and revocation instructions, please refer to the <Link to="/privacy-policy/">privacy policy</Link>).
                                </Checkbox>
                                <p className="required">* Required fields </p>
                                <span className="submit" onClick={() => handleSubmit()}>SEND</span>
                                {error &&
                                    <p className="info">Please check all required fields</p>
                                }
                                {isLoading === 'loading' &&
                                    <p className="info">Sending data...</p>
                                }
                                {isLoading === 'loaded' &&
                                    <p className="info">Sending data finished</p>
                                }
                            </div>
                            <div>
                               
                                    <FileUpload
                                        type="image1"
                                        onChange={props => onChangeFile(props)}
                                        value={formData.image1}
                                        placeHolder="Portrait Shot *"
                                    />
                                    <FileUpload
                                        type="image2"
                                        onChange={props => onChangeFile(props)}
                                        value={formData.image2}
                                        placeHolder="Full Size Image"
                                    />
                                    <FileUpload
                                        type="image3"
                                        onChange={props => onChangeFile(props)}
                                        value={formData.image1}
                                        placeHolder="Polaroid 1"
                                    />
                                    <FileUpload
                                        type="image4"
                                        onChange={props => onChangeFile(props)}
                                        value={formData.image4}
                                        placeHolder="Polaroid 2"
                                    />
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;