import { put, takeEvery, delay, takeLatest, all, fork, call, select } from 'redux-saga/effects'
import { bookmarkConstants } from '../constants';


export function* handleBookmark(payload) {
    console.log('>>>')
    console.log(payload)
    
    try {

        //let model = {ID: 119}

        yield put({ type: bookmarkConstants.ADDREMOVETOCASTING_SUCCESS, id: payload.id  })
    } catch (error) {
        yield console.log('ERROR')
        //yield put(setError(error.toString()));
    }
}

export function* handleDeleteCasting() {
    yield put({ type: bookmarkConstants.REMOVEALLFROMCASTING_SUCCESS  })
}

export function* handlePdf(payload) {
    console.log('>>>')
    console.log(payload)
    
    try {

        //let model = {ID: 119}

        yield put({ type: bookmarkConstants.ADDREMOVETOPDF_SUCCESS, idModel: payload.idModel, idImage: payload.idImage  })
    } catch (error) {
        yield console.log('ERROR')
        //yield put(setError(error.toString()));
    }
}


export function* handleAddAllImages(payload) {
    console.log(payload)
    try {
        yield put({ type: bookmarkConstants.ADDALLIMAGESTOPDF_SUCCESS, idModel: payload.idModel, images: payload.images  })
    } catch (error) {
        yield console.log('ERROR')
    }
}

export function* handleRemoveAllImages(payload) {
    try {
        yield put({ type: bookmarkConstants.REMOVEALLIMAGESFROMPDF_SUCCESS, idModel: payload.idModel  })
    } catch (error) {
        yield console.log('ERROR')
    }
}

export default function* watchEventLoad() {
    yield takeEvery(bookmarkConstants.ADDREMOVETOCASTING_REQUEST, handleBookmark);
    yield takeEvery(bookmarkConstants.REMOVEALLFROMCASTING_REQUEST, handleDeleteCasting);
    yield takeEvery(bookmarkConstants.ADDREMOVETOPDF_REQUEST, handlePdf);

    yield takeEvery(bookmarkConstants.ADDALLIMAGESTOPDF_REQUEST, handleAddAllImages);
    yield takeEvery(bookmarkConstants.REMOVEALLIMAGESFROMPDF_REQUEST, handleRemoveAllImages);
}