import React, { useState, useEffect, useRef} from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, Link } from 'react-router-dom'

import { getPage, setLoading } from 'actions'

import Home from './contents/home'
import ModelList from './contents/modelList'
import Model from './contents/model'
import Text from './contents/text'
import Contact from './contents/contact'
import Signup from './contents/signup'

import styles from './Page.module.sass'

const contents = {
    home: Home,
    modelList: ModelList,
    model: Model,
    text: Text,
    contact: Contact,
    signup: Signup
}

const Index = ({location, getPage, data, path, type, fixed, style }) => {

    //console.log(path, type)

    useEffect(() => {
        //console.log('load page')
        
        if(fixed) {
            getPage('/artists/')
        } else {
            getPage(path)
        }

    }, [])

    const test = useRef(0);

    useEffect(() => {
        const scroll = () => {
            if(type==='in') {
                //console.log(window.pageYOffset)
                test.current = window.pageYOffset;
            }
        }

        window.addEventListener('scroll', scroll)
        window.addEventListener('resize', scroll)

        return () => {
            window.removeEventListener('scroll', scroll)
            window.removeEventListener('resize', scroll)
        }

    }, [type])

    useEffect(() => {
		if(data) {
 
            document.title =  `MINT ARTIST MANAGEMENT – ${String(data.title).replace(/&#038;/g, "&").replace(/&#8211;/g, "–")}`
            
		}		
    }, [data])
    
    useEffect(() => {

       

    }, [type])

    //console.log(data)

    //if(!data) return null

    const Content = data ? contents[data.template] : null;

    let classNames = [styles.page]
    data && data.template && classNames.push(data.template)
    //type && classNames.push(type)
    
    /*

    {!data &&
				<Loading />
            }
            
    */

    let pageStyle

    if(type==='out') {
        console.log('OUT: ' + test.current)
        pageStyle = {position: 'fixed', zindex: -1, top: `-${test.current}px`, opacity: 0, transitionDuration: '0.2s'}
        //window.scrollTo(0, 0)
        //setStyle(styleNew)
    } else {
        pageStyle = {opacity: 1}
    }

    console.log('Render page')

    return (
        <div className={classNames.join(' ')} style={pageStyle}>
            {data &&
                <Content 
                    data={data} style={style}
                />
            }
        </div>
    );
};

const mapStateToProps = (state, props) => {
    const { pages } = state.pages
    const { style } = state.config

    let data = pages.find(page => page.path === props.path)
    
    console.log('>>>>>' + props.path)
    console.log(pages)
    console.log(style)

    let fixed = false
    
    if(data) {
        if(data.fixed) {
            fixed = true
            data = {...data}
            data.content = []

            let modelPage = pages.find(page => page.path === '/artists/')

            console.log(state.bookmarks.casting)
            console.log(modelPage)
            
            if(modelPage) {
                data.content = [...modelPage.content]
            } 

            
        }
    }
    
    console.log(data)

	return {
        data,
        style,
        fixed
	}
}

const mapDispatchToProps = dispatch => ({
    getPage: (path) => dispatch(getPage(path))
})

function propsAreEqual(prev, next) {
    return false
    
    console.log('cancel render')
}

const enhance = compose(
    withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(React.memo(Index, propsAreEqual));