const ScaleSize = (size, min, scale, unit) => {
    unit = unit ? unit : 'px'
    return size*scale > min ? `${size*scale}${unit}` : `${min}${unit}`
}

function ValidateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export { 
    ScaleSize,
    ValidateEmail
}