import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { withRouter, Link, NavLink } from 'react-router-dom'
import styles from './Navigation.module.sass'

import { getConfig } from 'actions'

import Icon from 'components/Icon'

const Navigation = ({ navigation, getConfig, location, loading, casting }) => {
    const [open, setOpen] = useState(false)
    const [ animateLoading, setAnimateLoading ] = useState(false);
    const [ isHome, setIsHome ] = useState( location.pathname === '/' )

    const animateLoadingStart = useRef(null)
    const ref = useRef(null)

    const [isScrolled, setIsScrolled] = useState(false)

    useEffect(() => {
        getConfig()

        const scroll = () => {
            const scrollTop = window.scrollY

            setIsScrolled(scrollTop>window.innerHeight/2)

        }

        window.addEventListener('scroll', scroll)
        window.addEventListener('resize', scroll)

        return () => {
            window.removeEventListener('scroll', scroll)
            window.removeEventListener('resize', scroll)
        }

    }, [])

    useEffect(() => {
        setIsHome(location.pathname === '/') 
        
        if (open) setOpen(false)    
    }, [location])

    useEffect(() => {
        if(loading === animateLoading) return

        if(loading) {
            animateLoadingStart.current = new Date().getTime();
            setAnimateLoading(true)
        }

        if(!loading) {
            const delay =  1200 - (new Date().getTime() - animateLoadingStart.current)%1200 ;
        
            setTimeout(() => {
                setAnimateLoading(false)
            }, delay);
        }
    }, [loading])

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick)

        return () => {
            document.removeEventListener('click', handleOutsideClick)
        }
    }, [open])

    const handleOutsideClick = (event) => {
        if (open && ref.current && !ref.current.contains(event.target)) {
            setOpen(false)
        }
    }

    const classesNavigation = [styles.navigation]
    open && classesNavigation.push('show-navigation')
    
    const classesHeader = []
    isHome && classesHeader.push('home')
    isScrolled && classesHeader.push('scrolled')

    return (
        <>
            <div id={styles.header} className={classesHeader.join(' ')}>
                <Link to="/" className="logo"></Link>
            </div>
            <NavLink className={styles.casting} to="/casting/">Casting<span>{casting.length}</span></NavLink>
            <span
                className={styles.menu + (open ? ' active': '')}
                onClick={() => setOpen(!open)}>
                    <span />
            </span>
            <div className={classesNavigation.join(' ')} ref={ref}>
                
                <ul>
                    {navigation.main.map((item, i) => (
                        <li key={i}><NavLink to={item.path} key={i}>{item.title}</NavLink></li>
                    ))}
                </ul>
                <ul>
                    {navigation.meta.map((item, i) => (
                        <li key={i}><NavLink to={item.path} key={i} dangerouslySetInnerHTML={{__html: item.title}}></NavLink></li>
                    ))}
                </ul>
                <div className="social">
                    <h6>Follow us on:</h6>
                    <ul>
                        <li><a href="https://www.instagram.com/mintartistmanagement" target="_blank"><Icon type="instagram" /></a></li>
                        <li><a href="https://models.com/agency/mint-artist-management" target="_blank"><Icon type="models" /></a></li>
                    </ul>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state, props) => {
    const { navigation } = state.config   
    const { loading, pages } = state.pages
    const { casting } = state.bookmarks

    const data = pages.find(page => page.path === props.location.pathname) 

    //console.log(data)
    
    return {
        navigation,
        loading,
        casting
    }
}

const mapDispatchToProps = dispatch => ({
    getConfig: () => dispatch(getConfig()),
})

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Navigation))