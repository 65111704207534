import { combineReducers } from 'redux'
import { config } from './config';
import { pages } from './pages';
import { bookmarks } from './bookmarks';

const rootReducer = combineReducers({
    config,
    pages,
    bookmarks
})

export default rootReducer