import React from 'react';
import styles from './Cookie.module.sass'
import stylesForm from 'components/page/parts/form/Form.module.sass'
import { Checkbox } from 'components/page/parts/form'

import { connect } from 'react-redux'
import { setCookie } from 'actions'

const Index = ({cookie, setCookie}) => {

    if(cookie) {
        return null
    }

    return (
        <div className={styles.cookie}>
            <div>
                <div className="center">
                    <div className={stylesForm.form}>
                        <div>
                            <h6>Datenschutzeinstellungen</h6>
                            <p>Wir nutzen Cookies auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrung zu verbessern.</p>
                            <Checkbox checked>
                                Essenziell
                            </Checkbox>
                        </div>
                        <div>
                            <span className="accept" onClick={() => setCookie()}>Speichern</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state, props) => {
    const { cookie } = state.config

	return {
        cookie
	}
}

const mapDispatchToProps = dispatch => ({
    setCookie: () => dispatch(setCookie())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Index)
