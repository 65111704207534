import { configConstants, pageConstants, bookmarkConstants } from '../constants';

const getConfig = () => ({
    type: configConstants.GETCONFIG_REQUEST
});

const setMeasurements = country => ({
    type: configConstants.SETMEASUREMENTS_REQUEST,
    country
});

const setCookie = () => ({
    type: configConstants.SETCOOKIE_REQUEST
});

const getPage = path => ({
    type: pageConstants.GETPAGE_REQUEST,
    path,
});

const addRemoveToCasting = id => ({
    type: bookmarkConstants.ADDREMOVETOCASTING_REQUEST,
    id
});

const removeAllFromCasting = id => ({
    type: bookmarkConstants.REMOVEALLFROMCASTING_REQUEST,
    id
});

const addRemoveImageToPdf = (idModel, idImage) => ({
    type: bookmarkConstants.ADDREMOVETOPDF_REQUEST,
    idModel,
    idImage
});

const addAllImagesToPdf = (idModel, images) => ({
    type: bookmarkConstants.ADDALLIMAGESTOPDF_REQUEST,
    idModel,
    images
});

const removeAllImagesFromPdf = (idModel) => ({
    type: bookmarkConstants.REMOVEALLIMAGESFROMPDF_REQUEST,
    idModel
});

const resize = () => ({
    type: configConstants.RESIZE_REQUEST
});

const setLoading = loading => ({
    type: pageConstants.SETLOADING_REQUEST,
    loading,
});

export {
    getConfig,
    setMeasurements,
    setCookie,
    getPage,
    addRemoveToCasting,
    removeAllFromCasting,
    addRemoveImageToPdf,
    resize,
    setLoading,
    addAllImagesToPdf,
    removeAllImagesFromPdf
}
