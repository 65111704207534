import { configConstants } from '../constants';
import { ScaleSize } from 'helper'

function setStyle() {
    let documentWidth = document.documentElement.clientWidth
    
    let width

    if(documentWidth>1275) {
        width = 1100
    } else {
        width = documentWidth-(0.135*documentWidth)
    }

    let scale = width/1100

    return {
        height: window.innerHeight,
        contentWidth: width,
        borderTitle: {
            fontSize: ScaleSize(2.8125, 1.6, scale, 'rem')
        },
        borderTitleLeft: {
            marginLeft: 1-scale * -30 + 'px'
        }
    }
}

let cookie = JSON.parse(localStorage.getItem('cookie'));

const initialState = {
    navigation: {  
        main: [],
        meta: []
    },
    categories: [],
    measurements: 'eu',
    style: setStyle(),
    cookie: cookie ? cookie : false
}

export function config(state = initialState, action) {
    //console.log(action)

    switch (action.type) {
        case configConstants.GETCONFIG_REQUEST:
            return {
                ...state
            }
        case configConstants.GETCONFIG_SUCCESS:

            return {
                ...state,
                navigation: action.data.navigation,
                categories: action.data.categories,
                colorSecondary: action.data.colorSecondary
            }
        case configConstants.GETCONFIG_FAILURE:
            return {
                error: action.error
            }

        case configConstants.SETMEASUREMENTS_SUCCESS:
            //console.log(action.country)
            return {
                ...state,
                measurements: action.country
            }

        case configConstants.SETCOOKIE_SUCCESS:
            //console.log(action.country)

            localStorage.setItem('cookie', JSON.stringify(true));

            return {
                ...state,
                cookie: true
            }

        case configConstants.RESIZE_SUCCESS:

            let test = setStyle();
            console.log(test)

            return {
                ...state,
                style: test
            }

        default:
            return state
    }
}