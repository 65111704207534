import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux'
import { withRouter, Link, NavLink } from 'react-router-dom';
import styles from './ModelList.module.sass'
import { addRemoveToCasting, removeAllFromCasting } from 'actions'

import moment from 'moment'
import { useState } from 'react';

import LazyImage from 'components/page/parts/lazyImage'




const Index = withRouter(({data, location, history, casting, addRemoveToCasting, removeAllFromCasting, style, categoriesExtended}) => {

    const [ filters, setFilters ] = useState({
        
    })
    const [ dimensions, setDimensions ] = useState({
        width: (style.contentWidth+30),
        //columns: (style.contentWidth+30)>800 ? 3 : (style.contentWidth+30)>400 ? 2 : 1
        columns: (style.contentWidth+30)>800 ? 3 : 2
    });

    const [ isCasting, setIsCasting ] = useState(location.pathname==='/casting/')

    
    //const [ extendedCategory, setExtendedCategory ] = useState(null);

    let bookmarksNew = casting

    
    
    const [ bookmarks, setBookmarks ] = useState(bookmarksNew) 

    const ref = useRef(null)
    const skipAnimate = useRef(null)

    useEffect(() => {
        const hash = location.hash;     
        const gender = hash.includes('women') ? 'woman' : hash.includes('men') ? 'man' : null
        
        
        let category = location.pathname.indexOf('/models/') > -1 ? 'models' : 'artists'
        
        let extendedCategory = null;

        if(location.pathname==='/models/') {
            categoriesExtended.forEach((item) => {
                if(hash.includes(item.slug)) extendedCategory = item.ID
            })
        }

        


        if(location.pathname==='/models/' || location.pathname==='/artists/') {
            setFilters({
                category,
                gender,
                extendedCategory
            })

            //setExtendedCategory(category === 'models' ? extendedCategory : null)

            /*
            setTimeout(() => { 
                window.dispatchEvent(new Event('resize'));
            }, 500);               
            */ 
        }

        if(location.pathname==='/casting/') {
            
            let m = new URLSearchParams(location.search).get('m')

            if(m) {
            
                bookmarksNew = m.split(',').map(value => (parseInt(value)))
                setBookmarks(bookmarksNew)
            } else {
                setBookmarks(casting)
            }
        }

    }, [location, casting, categoriesExtended])

    useEffect(() => {
       

        if(location.pathname==='/models/' || location.pathname==='/artists/') {
            
            setBookmarks(casting)
        }

    }, [ casting])

    useEffect(() => {
        const width = (style.contentWidth+30)
        ///const columns = (style.contentWidth+30)>800 ? 3 : (style.contentWidth+30)>400 ? 2 : 1
        const columns = (style.contentWidth+30)>800 ? 3 : 2
        //console.log(columns + '/' + dimensions.columns)
        //if(columns===dimensions.columns) skipAnimate.current = true
        skipAnimate.current = true

        setDimensions({
            width,
            columns
        })        

        

    }, [style])

    /*
    const hash = location.hash.replace(/#/, '')
    const gender = !hash ? '' : hash==='women' ? 'woman' :  'man'
    */
    
    /* //// */

    const copyToClipboard = () => {

        let str = window.location.href.slice(0, window.location.href.lastIndexOf('/')) + '/?m=' + casting.join(',')

        const el = document.createElement('textarea');
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    const link = window.location.href.slice(0, window.location.href.lastIndexOf('/')) + '/?m=' + casting.join(',')
    const body = `Dear%20Client,%0D%0Awe%20have%20composed%20an%20individual%20casting%20for%20you,%20please%20click%20on%20the%20following%20link%20to%20review%20or%20edit%3A%0D%0A%0D%0A${encodeURIComponent(link)}%0D%0A%0D%0AIn%20case%20you%20have%20any%20questions%20please%20feel%20free%20to%20contact%20us.%20Meanwhile%20we%20are%20very%20looking%20forward%20to%20your%20feedback.%0D%0A%0D%0AYours%20sincerly%0D%0AXXX`;
    const testlink = "mailto:?subject=Casting&body=" + body;

    const handleBookmark = (id) => {
        
            
        let m = new URLSearchParams(location.search).get('m')

        if(m) {
        
            bookmarksNew = m.split(',').map(value => (parseInt(value)))
            
            console.log(bookmarksNew)

            bookmarksNew = bookmarksNew.filter(bookmark => bookmark !== id)

            history.replace({...location, search: '?m=' + bookmarksNew.join(',')})


        } else {
            addRemoveToCasting(id)
        }
      
    }

    const handleRemoveAll = () => {

        let m = new URLSearchParams(location.search).get('m')

        if(m) {

            history.replace({...location, search: '?m='})

        } else {
            removeAllFromCasting();
        }
        

    }

    /* /////// */



    let html 
    let height = 10000

    //console.log(data.content)
    //console.log(isCasting)
    //console.log(bookmarks)
    
    console.log(categoriesExtended)

    console.log('>> ' + dimensions.width)

       

        let teaserWidth = (dimensions.width)/dimensions.columns

       

        //console.log(teaserWidth)

        let visibleTotal 

        if(isCasting) {
            visibleTotal = casting.length
        } else {
            visibleTotal = data.content.filter(item => item.categories.findIndex(category => category === filters.category) > -1 && (item.gender === filters.gender || !filters.gender) ).length
        }

        let hiddenTotal = data.content.length - visibleTotal

        let visibleCount = 0
        let hiddenCount = visibleTotal

        //console.log(visibleTotal, visibleCount, hiddenTotal, hiddenCount)

        //console.log(casting)
        

        html = data.content.map((item, i) => {

            let visible = item.categories.findIndex(category => category === filters.category) > -1 && (item.gender === filters.gender || !filters.gender)

            if(visible && filters.category === 'models' && filters.extendedCategory) {
                visible = item.categories_extended.includes(filters.extendedCategory) 
            }

            const bookmarked = bookmarks.findIndex(bookmark => bookmark === item.ID)>-1

            if(isCasting) {
               
                if(bookmarked) {
                    visible = 1
                } else {
                    visible = 0
                }
                
            }

            let position = visible ? visibleCount : i

            //console.log()
            
            let xPos = position%dimensions.columns
            let yPos = Math.floor(position/dimensions.columns)
            


            //console.log(position, visible, xPos, yPos)

            let style = {
                width: `${100/dimensions.columns}%`,
                transform: `translate(${xPos*(100)}%, ${yPos*(teaserWidth+25)}px) scale(${visible ? 1 : 0})`,
                opacity: visible ? 1 : 0
               
            }

            if(visible) {
                visibleCount++
            } else {
                //hiddenCount++
            }

            return (
                <li key={item.ID} style={style}>
                    <div>
                        <Link 
                            className="clickArea"
                            to={`/${item.category}/${item.name}`} />
                        <div className="image">
                            {item.image &&
                                <LazyImage
                                    image={item.image.path}
                                    />
                            }
                        </div>
                        <span className={'casting' + (bookmarked ? ' active' : '')} onClick={() => handleBookmark(item.ID)}></span>
                        <h3>{item.title}</h3>
                    </div>
                </li>
            )
        })

        height = Math.ceil(visibleCount/dimensions.columns)*(teaserWidth+25)

    if(visibleCount===0) {
        html = <li className="rich empty"><p>Find models <Link to="/models/">here</Link> and start your casting</p></li>
        height = 300
    }

    const classes = [styles.modelList, 'center']
    skipAnimate.current && classes.push('noanimate')

    skipAnimate.current = false

    const createFilterLink = ({gender=null, extendedCategory=null}) => {
        let hash = [];

        if(gender) hash.push(gender === 'woman' ? 'women' : 'men')
        if(extendedCategory) {
            
            let category = categoriesExtended.find((item) => item.ID === extendedCategory)
            if(category) {
                hash.push(category.slug)
            }
        }
        
        return {
            ...location,
            hash: hash.length > 0 ? `#${hash.join(',')}` : null 
        }

    }

    console.log(filters)

    return (
        <div className={classes.join(' ')}>
            <div className="left">
                <div>
                    {!isCasting &&
                        <>
                            <ul className="actions">
                                <li><NavLink to="/models/">Models</NavLink></li>
                                <li><NavLink to="/artists/">Artists</NavLink></li>
                            </ul>
                            <div className="categories">
                                {filters.category === 'models' &&
                                    <ul className="actions">
                                        <li>
                                            <NavLink to={createFilterLink({gender: filters.gender})} isActive={() => filters.extendedCategory === null}>All</NavLink>
                                        </li>
                                    {categoriesExtended.map((item, index) => (
                                        <li key={index}>
                                            <NavLink to={createFilterLink({gender: filters.gender, extendedCategory: item.ID})}  isActive={() => filters.extendedCategory === item.ID}>{item.title}</NavLink>
                                        </li>
                                    ))} 
                                    </ul>
                                }
                                <ul className="actions">
                                    <li><NavLink to={createFilterLink({gender: null, extendedCategory: filters.extendedCategory})} isActive={() => filters.gender === null}>All</NavLink></li>
                                    <li><NavLink to={createFilterLink({gender: 'man', extendedCategory: filters.extendedCategory})} isActive={() => filters.gender === 'man'}>Men</NavLink></li>
                                    <li><NavLink to={createFilterLink({gender: 'woman', extendedCategory: filters.extendedCategory})} isActive={() => filters.gender === 'woman'}>Women</NavLink></li>
                                </ul>
                               
                            </div>
                        </>
                    }
                    {isCasting &&
                        <ul className="actions">
                            <li><span onClick={() => copyToClipboard()}>Copy casting link</span></li>
                            <li><a href={testlink}>Mail casting link</a></li>
                            <li><span onClick={() => handleRemoveAll()}>Clear all</span></li>
                        </ul>
                    }
                    <div className="list">
                        <span className="border"><h1 style={style.borderTitle}>{isCasting ? 'casting' : 'talents'}</h1></span>
                        <ul ref={ref} style={{height: `${height}px`}}>
                            {html}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
});

const mapStateToProps = (state, props) => {
    const { measurements, categories } = state.config
    let { casting } = state.bookmarks

	return {
        measurementsCountry: measurements,
        casting,
        categoriesExtended: categories
	}
}

const mapDispatchToProps = dispatch => ({
    addRemoveToCasting: (id) => dispatch(addRemoveToCasting(id)),
    removeAllFromCasting: () => dispatch(removeAllFromCasting())
})

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Index))

