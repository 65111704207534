import { put, takeEvery, delay, takeLatest, all, fork, call, select } from 'redux-saga/effects'
import { configConstants } from '../constants';
import { fetchPage } from '../api';

export function* handleConfigLoad(payload) {
    console.log(payload)
    try {
        //yield console.log('config')
        const data = yield call(fetchPage, '/?config');
        yield put({ type: configConstants.GETCONFIG_SUCCESS, data })
    } catch (error) {
        yield console.log('ss')
        //yield put(setError(error.toString()));
    }
}

export function* handleSetMeasurements(payload) {
    console.log(payload)
    try {
        yield put({ type: configConstants.SETMEASUREMENTS_SUCCESS, country: payload.country })
    } catch (error) {
        yield console.log('ss')
        //yield put(setError(error.toString()));
    }
}

export function* handleSetCookie() {
    console.log('Ss')
    try {
        yield put({ type: configConstants.SETCOOKIE_SUCCESS})
    } catch (error) {
        yield console.log('ss')
        //yield put(setError(error.toString()));
    }
}

export function* handleResize(payload) {
    console.log(payload)
    try {
        yield put({ type: configConstants.RESIZE_SUCCESS })
    } catch (error) {
        yield console.log('ss')
        //yield put(setError(error.toString()));
    }
}

export default function* watchConfigLoad() {
    yield takeEvery(configConstants.GETCONFIG_REQUEST, handleConfigLoad);
    yield takeEvery(configConstants.SETMEASUREMENTS_REQUEST, handleSetMeasurements);
    yield takeEvery(configConstants.SETCOOKIE_REQUEST, handleSetCookie);
    yield takeEvery(configConstants.RESIZE_REQUEST, handleResize);
}